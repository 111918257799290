import React, { useEffect, useState } from 'react';
import * as ethers from 'ethers';
import erc20Abi from '../../modules/Swap/abis/erc20.json';
export default function TokenERC20({ tokenAddress, rpcUrl, poolAddress }) {
  const [tokenDetails, setTokenDetails] = useState(null);
  useEffect(() => {
    const retriveToken = async () => {
      if (!tokenAddress || !rpcUrl) {
        return;
      }
      console.log('retriveToken');
      const provider = new ethers.JsonRpcProvider(rpcUrl);
      const tokenContract = new ethers.Contract(tokenAddress, erc20Abi, provider);
      try {
        const name = await tokenContract.name();
        const symbol = await tokenContract.symbol();
        const decimals = await tokenContract.decimals();
        const totalSupply = await tokenContract.totalSupply();
        // Format the total supply (considering decimals)
        const formattedSupply = ethers.formatUnits(totalSupply, decimals);
        const formattedDecimals = decimals.toString();
        // Return the details
        return {
          name,
          symbol,
          decimals: formattedDecimals,
          totalSupply: formattedSupply,
        };
      } catch (error) {
        console.error('Error fetching token info:', error);
      }
    };

    retriveToken().then((tokenDetails) => {
      setTokenDetails(tokenDetails);
    });
  }, [tokenAddress, rpcUrl]);

  return (
    <div>
      {tokenDetails && (
        <div>
          <p>Name: {tokenDetails.name}</p>
          <p>Symbol: {tokenDetails.symbol}</p>
          <p>Decimals: {tokenDetails.decimals}</p>
          <p>Total Supply: {tokenDetails.totalSupply}</p>
          {poolAddress && tokenDetails.symbol && <p>{'Pool: ' + 'WETH -> ' + tokenDetails.symbol}</p>}
          <p>Pool address: {poolAddress}</p>
        </div>
      )}
    </div>
  );
}
