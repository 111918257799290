import React, { useState, useEffect } from 'react';

import * as DEPLOYMENTS from './contract';
import BackBtn from '../../components/Buttons/BackBtn';
import RPCs from '../../vars/rpc';
import DefaultBtn from '../../components/Buttons/DefaultBtn';
import useRpcStore from '../../store/rpc';
import useWalletsStore from '../../store/wallets';
import TokenERC20 from '../../components/Token/Token';
import * as ethers from 'ethers';
const getLocalStorage = (key, defaultValue) => {
  const storedValue = localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : defaultValue;
};
export default function Swap() {
  const [poolAdr, setPoolAdr] = useState('');
  const { getSelected, selected } = useRpcStore();
  const { wallets } = useWalletsStore();
  const [swapAmount, setSwapAmount] = useState(getLocalStorage('swapAmount', '0.0005'));
  const [chainId, setChainId] = useState(8453);

  const [tokenOut, setTokenOut] = useState(
    getLocalStorage('tokenOut', {
      address: '',
      decimals: 18,
      symbol: 'TokenOut',
      isToken: true,
      isNative: true,
      wrapped: false,
    })
  );

  const [tokenIn, setTokenIn] = useState(DEPLOYMENTS.deployments[Number(chainId)].WRAPED_NATIVE_TOKEN);

  const [delay, setDelay] = useState(getLocalStorage('delay', 3000));
  const provider = new ethers.JsonRpcProvider(selected.url);
  const factoryContract = new ethers.Contract(
    DEPLOYMENTS.deployments[chainId].UniswapV3Factory.address[0],
    DEPLOYMENTS.deployments[chainId].UniswapV3Factory.abi,
    provider
  );
  const quoterContract = new ethers.Contract(DEPLOYMENTS.deployments[chainId].QuoterV2.address[0], DEPLOYMENTS.deployments[chainId].QuoterV2.abi, provider);
  const setTokenOutAddress = (address) => setTokenOut((prev) => ({ ...prev, address }));
  const setTokenOutDecimals = (decimals) => {
    setTokenOut((prev) => ({ ...prev, decimals: decimals }));
  };

  useEffect(() => {
    const getChainId = async () => {
      try {
        console.log('Fetching chain ID...');
        const provider = new ethers.JsonRpcProvider(selected.url);
        const network = await provider.getNetwork();
        console.log('Chain ID: ' + network.name);
        setChainId(network.chainId.toString());
        setTokenIn(DEPLOYMENTS.deployments[Number(network.chainId.toString())].WRAPED_NATIVE_TOKEN);
        console.log('Chain ID: ' + network.chainId.toString());
      } catch (error) {
        console.error('Error fetching chain ID:', error);
      }
    };

    if (selected) {
      getChainId();
    }
  }, [selected]);

  async function approveToken(tokenAddress, tokenABI, amount, wallet) {
    try {
      const tokenContract = new ethers.Contract(tokenAddress, tokenABI, wallet);

      const currentAllowance = await tokenContract.allowance(wallet.address, DEPLOYMENTS.deployments[chainId].SwapRouter02.address[0]);
      if (currentAllowance >= amount) {
        console.log('[approveToken] => Token is already approved for the requested amount');
        return 'already approved';
      }

      console.log('[approveToken] => approving token...');

      const approveTransaction = await tokenContract.approve.populateTransaction(
        DEPLOYMENTS.deployments[chainId].SwapRouter02.address[0],
        ethers.parseEther(amount.toString())
      );

      const transactionResponse = await wallet.sendTransaction(approveTransaction);
      const receipt = await transactionResponse.wait();
      console.log('[approveToken] => approved ' + transactionResponse.hash);
      return transactionResponse.hash;
    } catch (error) {
      console.error('An error occurred during token approval:', error);
      throw new Error('Token approval failed');
    }
  }

  async function getPoolInfo(factoryContract, tokenIn, tokenOut) {
    try {
      const poolAddress = await factoryContract.getPool(tokenIn.address, tokenOut.address, 3000);

      if (!poolAddress || poolAddress === ethers.ZeroAddress) {
        throw new Error('Failed to get pool address or invalid pool address');
      }

      console.log('[getPoolInfo] => pool address found: ', poolAddress);
      const poolContract = new ethers.Contract(poolAddress, DEPLOYMENTS.ABI.Pool, provider);

      const [token0, token1, fee] = await Promise.all([poolContract.token0(), poolContract.token1(), poolContract.fee()]);

      return { poolContract, token0, token1, fee, poolAddress };
    } catch (error) {
      console.error('Error while fetching pool information:', error.message);
      if (error.code) {
        console.error('Error code:', error.code);
      }
      return { poolContract: null, token0: null, token1: null, fee: null, poolAddress: null };
    }
  }

  async function quoteSwap(quoterContract, tokenOut, fee, signer, amountIn) {
    try {
      // Call the quoteExactInputSingle method to get the quoted output amount
      const quotedAmountOut = await quoterContract.quoteExactInputSingle.staticCall({
        tokenIn: DEPLOYMENTS.deployments[chainId].WRAPED_NATIVE_TOKEN.address,
        tokenOut: tokenOut.address,
        fee: fee,
        recipient: signer.address,
        deadline: Math.floor(new Date().getTime() / 1000 + 60 * 10), // 10 minutes from now
        amountIn: amountIn,
        sqrtPriceLimitX96: 0,
      });

      // Convert the quoted amount to the appropriate decimals and log the result
      const amountOut = ethers.formatUnits(quotedAmountOut[0], tokenOut.decimals);
      console.log('[quoteSwap] => Quoted amount to swap: ', amountOut);
      return amountOut;
    } catch (error) {
      // Log the error if any issue occurs during the quote process
      console.error('Error while quoting swap:', error.message);
      if (error.code) {
        console.error('Error code:', error.code);
      }
      throw new Error('Error in quoteSwap function');
    }
  }

  async function prepareSwapParams(poolContract, tokenOut, signer, amountIn, amountOut) {
    return {
      tokenIn: DEPLOYMENTS.deployments[chainId].WRAPED_NATIVE_TOKEN.address,
      tokenOut: tokenOut.address,
      fee: await poolContract.fee(),
      recipient: signer.address,
      amountIn: amountIn,
      amountOutMinimum: amountOut,
      sqrtPriceLimitX96: 0,
    };
  }
  async function wrapETH(amountIn, signer) {
    const wethContract = new ethers.Contract(
      DEPLOYMENTS.deployments[chainId].WRAPED_NATIVE_TOKEN.address,
      DEPLOYMENTS.deployments[chainId].WRAPED_NATIVE_TOKEN.abi,
      signer
    );

    const balance = await wethContract.balanceOf(signer.address);

    if (balance >= amountIn) {
      console.log('[wrapETH] => Already wrapped enough WETH' + amountIn);

      return 'already wrap';
    }

    const tx = await wethContract.deposit({
      value: amountIn,
    });

    await tx.wait();

    console.log('[wrapETH] => wrapped ' + tx?.hash);

    return tx?.hash;
  }
  async function executeSwap(swapRouter, params, signer) {
    try {
      const transaction = await swapRouter.exactInputSingle.populateTransaction(params);

      const transactionResponse = await signer.sendTransaction(transaction);
      console.log('[executeSwap] => transaction sent... : ' + transactionResponse.hash);

      const receipt = await transactionResponse.wait();

      console.log('[executeSwap] => transaction confirmed');

      return transactionResponse.hash;
    } catch (error) {
      console.error('Error while executing swap:', error.message);
      if (error.code) {
        console.error('Error code:', error.code);
      }
      throw new Error('Error in executeSwap function');
    }
  }

  const handleSubmit = async () => {
    if (!tokenIn.address) {
      console.log('Token in is empty');
      return;
    }
    if (!tokenOut.address) {
      console.log('Token out is empty', tokenOut);
      return;
    }
    if (!swapAmount) {
      console.log('Swap amount is empty');
      return;
    }
    if (!selected) {
      console.log('Selected chain is empty');
      return;
    }
    if (!delay) {
      console.log('Delay is empty');
      return;
    }

    const amountIn = ethers.parseUnits(swapAmount.toString(), 18);

    const privateKeys = wallets.map((item) => item);

    console.log('[SWAP] => Starting job with ' + privateKeys.length + ' private keys...');

    let errored = [];

    const { poolContract, token0, token1, fee } = await getPoolInfo(factoryContract, DEPLOYMENTS.deployments[chainId].WRAPED_NATIVE_TOKEN, tokenOut);

    if (!poolContract || !token0 || !token1 || !fee) {
      console.log('[SWAP] => Cannot get POOL');
      return;
    }

    for (var i = 0; i < privateKeys.length; i++) {
      const signer = new ethers.Wallet(privateKeys[i], provider);
      try {
        console.log(`--------------------${i}/${privateKeys.length} Wallets.-------------------`, { color: 'red' });
        const wrap = await wrapETH(amountIn, signer);
        const approve = await approveToken(tokenIn.address, DEPLOYMENTS.deployments[chainId].WRAPED_NATIVE_TOKEN.abi, amountIn, signer);
        const quotedAmountOut = await quoteSwap(quoterContract, tokenOut, fee, signer, amountIn);
        const params = await prepareSwapParams(poolContract, tokenOut, signer, amountIn, quotedAmountOut[0].toString());
        const swapRouter = new ethers.Contract(
          DEPLOYMENTS.deployments[chainId].SwapRouter02.address[0],
          DEPLOYMENTS.deployments[chainId].SwapRouter02.abi,
          signer
        );

        const swap = await executeSwap(swapRouter, params, signer);

        await new Promise((resolve) => setTimeout(resolve, delay));
      } catch (error) {
        errored.push(privateKeys[i]);
      }
    }
    console.log('[SWAP] => Finished job with ' + errored.length + ' errored private keys...');
    if (errored.length === 0) {
      console.log('[SWAP] => All private keys executed successfully');
    } else {
      console.log('[SWAP] => Errored private keys: ' + errored);
    }
  };

  useEffect(() => {
    if (tokenOut?.address && tokenIn?.address && chainId) {
      getPoolInfo(factoryContract, tokenIn, tokenOut).then((poolInfo) => {
        setPoolAdr(poolInfo.poolAddress);
      });
    }
  }, [tokenOut, tokenIn, chainId]);

  return (
    <div className="module">
      <BackBtn />
      <h1>swap</h1>
      <p>Be sure that all fields are filled and valid</p>
      <span>Paste fields below:</span>
      <div className="form__group field">
        <input
          type="input"
          className="form__field"
          placeholder="Name"
          name="tokenOut"
          id="tokenOut"
          required
          value={tokenOut.address}
          onPaste={(e) => {
            e.preventDefault();
            setTokenOutAddress(e.clipboardData.getData('text'));
          }}
          onChange={(e) => setTokenOutAddress(e.target.value)}
        />
        <label htmlFor="tokenOut" className="form__label">
          Token address
        </label>
      </div>
      <TokenERC20 tokenAddress={tokenOut.address} rpcUrl={selected.url} poolAddress={poolAdr} />
      <div className="form__group field">
        <input
          type="input"
          className="form__field"
          placeholder="Name"
          name="tokenOutdec"
          id="tokenOutdec"
          required
          value={tokenOut.decimals}
          onPaste={(e) => {
            e.preventDefault();
            setTokenOutDecimals(e.clipboardData.getData('text'));
          }}
          onChange={(e) => setTokenOutDecimals(e.target.value)}
        />
        <label htmlFor="tokenOutdec" className="form__label">
          Token decimals
        </label>
      </div>
      <div className="form__group field">
        <input
          type="number"
          className="form__field"
          placeholder="Name"
          name="swapAmount"
          id="swapAmount"
          required
          value={swapAmount}
          onPaste={(e) => {
            e.preventDefault();
            setSwapAmount(e.clipboardData.getData('text'));
          }}
          onChange={(e) => setSwapAmount(e.target.value)}
        />
        <label htmlFor="swapAmount" className="form__label">
          Transaction value (in ether)
        </label>
      </div>
      <p>Settings:</p>
      <span>Delay between wallets: </span>
      <input
        style={{
          appearance: 'none',
          outline: 'none',
          border: 'none',
          background: 'transparent',
          color: 'firebrick',
          borderBottom: '2px solid #9b9b9b',
          width: '60px',
        }}
        type="number"
        value={delay}
        onChange={(e) => setDelay(Number(e.target.value))}
      />{' '}
      <span>MS</span>
      <p></p>
      <DefaultBtn text={'SWAP'} clickHANDLER={handleSubmit} />
    </div>
  );
}
