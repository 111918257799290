import './App.css';
import List from './components/Wallets/List';
import { HashRouter, Routes, Route } from 'react-router-dom';
import Menu from './components/Nav/Menu/Menu';
import Table from './components/Table/Table';
import modules from './modules';
import Console from './components/Console/Console';
import Header from './components/Header/Header';
import React from 'react';
import { ShaderGradientCanvas, ShaderGradient } from '@shadergradient/react';
import * as reactSpring from '@react-spring/three';

function App() {
  return (
    <div className="App press-start-2p-regular">
      <Header />
      <Console />
      <div className="flex-container">
        <List />
        <HashRouter>
          <Table>
            <Routes>
              <Route path="/" element={<Menu />} />

              {modules.map((m, i) => (
                <Route key={i} path={`/${m.title}`} element={<m.component />} />
              ))}
            </Routes>
          </Table>
        </HashRouter>
      </div>
      <ShaderGradientCanvas
        style={{
          position: 'absolute',
          zIndex: -1,
          top: 0,
        }}
      >
        <ShaderGradient
          control="query"
          urlString="https://www.shadergradient.co/customize?animate=on&axesHelper=off&bgColor1=%23000000&bgColor2=%23000000&brightness=1&cAzimuthAngle=180&cDistance=4.3&cPolarAngle=90&cameraZoom=1&color1=%230026ff&color2=%23db0004&color3=%2300e138&destination=onCanvas&embedMode=off&envPreset=lobby&format=gif&fov=50&frameRate=10&gizmoHelper=hide&grain=off&lightType=3d&pixelDensity=1.5&positionX=-1.4&positionY=0&positionZ=0&range=enabled&rangeEnd=40&rangeStart=0&reflection=0.1&rotationX=0&rotationY=10&rotationZ=50&shader=defaults&type=waterPlane&uAmplitude=0&uDensity=7&uFrequency=5.5&uSpeed=0.6&uStrength=0.6&uTime=0&wireframe=false"
        />
      </ShaderGradientCanvas>
    </div>
  );
}

export default App;
