import React from "react";

export default function DefaultBtn({ clickHANDLER, disabled, text }) {
  return (
    <button className="glowing-btn" onClick={clickHANDLER} disabled={disabled}>
      <span className="glowing-txt">
        {text.split("").map((letter, index) => (
          <span key={index} className={index == 1 ? "faulty-letter" : ""}>
            {letter}
          </span>
        ))}
      </span>
    </button>
  );
}
