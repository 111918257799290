import React, { Fragment } from 'react';
import modules from '../../../modules';
import MenuBtn from '../../Buttons/MenuBtn';

export default function Menu() {
  return (
    <div className="menu">
      <span>Avaliable modules:</span>
      {modules.map((m, i) => (
        <Fragment key={i}>
          <MenuBtn title={m.title} />
        </Fragment>
      ))}
    </div>
  );
}
