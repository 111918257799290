import React, { useState } from 'react';
import './RpcManage.css';
import useRpcStore from '../../store/rpc';
import RpcCell from './RpcCell';

export default function RpcManager() {
  const { rpc, addRpc, removeRpc, selected, setSelected } = useRpcStore();

  const selectHandler = (url) => {
    setSelected(url);
  };

  return (
    <div className="rpc-manager">
      {rpc.map((item, index) => (
        <RpcCell onClick={() => selectHandler(item.url)} selected={selected.url === item.url} URL={item.url} blockchainName={item.name} key={index} />
      ))}
    </div>
  );
}
