import React from "react";
import { Link } from "react-router-dom";

export default function MenuBtn({ title }) {
  return (
    <Link to={`/${title}`}>
      <div>{title}</div>
    </Link>
  );
}
