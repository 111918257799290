import React, { useEffect, useState } from 'react';
import InputDataDecoder from 'ethereum-input-data-decoder';
import BackBtn from '../../components/Buttons/BackBtn';
import DefaultBtn from '../../components/Buttons/DefaultBtn';
import RPCs from '../../vars/rpc';
import Web3 from 'web3';
import useWalletsStore from '../../store/wallets';

function randomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

export default function Mint() {
  const getLocalStorage = (key, defaultValue) => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  };

  const [transactionInput, setTransactionInput] = useState(getLocalStorage('transactionInput', ''));
  const [contractABI, setContractABI] = useState(getLocalStorage('contractABI', ''));
  const [signerAddress, setSignerAddress] = useState(getLocalStorage('signerAddress', ''));
  const [contractAddress, setContractAddress] = useState(getLocalStorage('contractAddress', ''));
  const [selectedChain, setSelectedChain] = useState(getLocalStorage('selectedChain', ''));
  const [delay, setDelay] = useState(getLocalStorage('delay', 10030));
  const [txvalue, setTxvalue] = useState(getLocalStorage('txvalue', ''));
  const [txdata, setTxdata] = useState(null);
  const { wallets } = useWalletsStore();

  const [pending, setPending] = useState(false);

  // Save states to localStorage on change
  useEffect(() => {
    localStorage.setItem('transactionInput', JSON.stringify(transactionInput));
  }, [transactionInput]);

  useEffect(() => {
    localStorage.setItem('contractABI', JSON.stringify(contractABI));
  }, [contractABI]);

  useEffect(() => {
    localStorage.setItem('signerAddress', JSON.stringify(signerAddress));
  }, [signerAddress]);

  useEffect(() => {
    localStorage.setItem('contractAddress', JSON.stringify(contractAddress));
  }, [contractAddress]);

  useEffect(() => {
    localStorage.setItem('selectedChain', JSON.stringify(selectedChain));
  }, [selectedChain]);

  useEffect(() => {
    localStorage.setItem('delay', JSON.stringify(delay));
  }, [delay]);

  useEffect(() => {
    localStorage.setItem('txvalue', JSON.stringify(txvalue));
  }, [txvalue]);

  useEffect(() => {
    document.body.style.pointerEvents = pending ? 'none' : 'auto';
  }, [pending]);

  useEffect(() => {
    if (!transactionInput || !contractABI) return;
    try {
      const decoder = new InputDataDecoder(contractABI);
      const decoded = decoder.decodeData(transactionInput);
      setTxdata(decoded);
    } catch (error) {
      console.error(error);
    }
  }, [transactionInput, contractABI]);

  const handleSubmit = async () => {
    if (!contractAddress) {
      console.log('Contract address is empty');
      return;
    }
    if (!transactionInput) {
      console.log('Transaction input is empty');
      return;
    }
    if (!contractABI) {
      console.log('Contract ABI is empty');
      return;
    }
    if (!signerAddress) {
      console.log('Signer address is empty');
      return;
    }
    if (!txvalue) {
      console.log('Transaction value is empty');
      return;
    }
    if (!selectedChain) {
      console.log('Selected chain is empty');
      return;
    }
    if (!delay) {
      console.log('Delay is empty');
      return;
    }
    if (!txdata) {
      console.log('Transaction data is empty');
      return;
    }
    if (wallets.length === 0) {
      console.log('Wallets is empty');
      return;
    }

    for (let i = 0; i < wallets.length; i++) {
      console.log(`Minting on wallet ${i + 1} of ${wallets.length}`);
      const web3 = new Web3(RPCs.find((item) => item.name === selectedChain).url);
      const sender = web3.eth.accounts.wallet.add(wallets[i])[0];
      const contract = new web3.eth.Contract(JSON.parse(contractABI), contractAddress);
      const gasPrice = web3.utils.toWei(randomNumber(0.0112598, 0.0352598).toString(), 'gwei');
      const inputs = txdata.inputs.map((param) => {
        if (param === signerAddress || param === signerAddress.replace('0x', '')) {
          return sender.address;
        }
        if (param?._isBigNumber) {
          return param._hex;
        }
        return param;
      });

      try {
        const tx = await contract.methods[txdata.method](...inputs).send({
          from: sender.address,
          value: web3.utils.toWei(txvalue, 'ether'),
          gasPrice,
        });

        // Calculate gas cost in ETH
        const gasUsed = Number(tx.gasUsed);
        const gasCostInWei = gasUsed * Number(gasPrice);
        const gasCostInEth = web3.utils.fromWei(gasCostInWei.toString(), 'ether');

        const totalSpentInEth = (parseFloat(txvalue) + parseFloat(gasCostInEth)).toFixed(6);

        console.log(`Transaction successful: ${tx.transactionHash}`);
        console.log(`Gas cost: ${gasCostInEth} ETH`);
        console.log(`Total spent: ${totalSpentInEth} ETH`);
      } catch (error) {
        console.error('Transaction failed:', error);
      }

      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  };

  return pending ? (
    <div className="console"></div>
  ) : (
    <div className="module">
      <BackBtn />
      <h1>Mint</h1>
      <p>Be sure that all fields are filled and valid</p>
      <span>Paste fields below:</span>
      <div className="form__group field">
        <input
          type="input"
          className="form__field"
          placeholder="Name"
          name="ctraddr"
          id="ctraddr"
          required
          value={contractAddress}
          onPaste={(e) => {
            e.preventDefault();
            setContractAddress(e.clipboardData.getData('text'));
          }}
          onChange={(e) => setContractAddress(e.target.value)}
        />
        <label htmlFor="ctraddr" className="form__label">
          Contract address
        </label>
      </div>
      <div className="form__group field">
        <input
          type="input"
          className="form__field"
          placeholder="Name"
          name="txinput"
          id="txinput"
          required
          value={transactionInput}
          onPaste={(e) => {
            e.preventDefault();
            setTransactionInput(e.clipboardData.getData('text'));
          }}
          onChange={(e) => setTransactionInput(e.target.value)}
        />
        <label htmlFor="txinput" className="form__label">
          Transaction input
        </label>
      </div>
      <div className="form__group field">
        <input
          type="input"
          className="form__field"
          placeholder="Name"
          name="ctrabi"
          id="ctrabi"
          required
          value={contractABI}
          onPaste={(e) => {
            e.preventDefault();
            setContractABI(e.clipboardData.getData('text'));
          }}
          onChange={(e) => setContractABI(e.target.value)}
        />
        <label htmlFor="ctrabi" className="form__label">
          Contract ABI
        </label>
        {txdata && <textarea className="decoded-txdata" readOnly value={JSON.stringify(txdata, null, 2)} />}
      </div>
      <div className="form__group field">
        <input
          type="input"
          className="form__field"
          placeholder="Name"
          name="signer"
          id="signer"
          required
          value={signerAddress}
          onPaste={(e) => {
            e.preventDefault();
            setSignerAddress(e.clipboardData.getData('text'));
          }}
          onChange={(e) => setSignerAddress(e.target.value)}
        />
        <label htmlFor="signer" className="form__label">
          Address who signs this transaction
        </label>
      </div>
      <div className="form__group field">
        <input
          type="input"
          className="form__field"
          placeholder="Name"
          name="txvalue"
          id="txvalue"
          required
          value={txvalue}
          onPaste={(e) => {
            e.preventDefault();
            setTxvalue(e.clipboardData.getData('text'));
          }}
          onChange={(e) => setTxvalue(e.target.value)}
        />
        <label htmlFor="txvalue" className="form__label">
          Transaction value (in ether)
        </label>
      </div>
      <p>Settings:</p>
      <div>
        <p>
          Chains:({selectedChain})
          {RPCs.map((item) => (
            <label key={item.name} style={{ display: 'block' }}>
              <input
                type="radio"
                name="chain"
                value={item.name}
                checked={selectedChain === item.name}
                onChange={() => setSelectedChain(item.name)}
              />
              {item.name}
            </label>
          ))}
        </p>
      </div>
      <span>Delay between wallets: </span>
      <input
        style={{
          appearance: 'none',
          outline: 'none',
          border: 'none',
          background: 'transparent',
          color: 'firebrick',
          borderBottom: '2px solid #9b9b9b',
          width: '60px',
        }}
        type="number"
        value={delay}
        onChange={(e) => setDelay(Number(e.target.value))}
      />{' '}
      <span>MS</span>
      <p></p>
      <DefaultBtn text={'MINT'} clickHANDLER={handleSubmit} />
    </div>
  );
}
