import React, { useEffect, useRef } from 'react';
import './style.css';

export default function Console() {
  const consoleRef = useRef(null);
  const consoleContentRef = useRef(null);
  const isDragging = useRef(false);
  const offset = useRef({ x: 200, y: 200 });

  const handleMouseDown = (e) => {
    const rect = consoleRef.current.getBoundingClientRect();
    offset.current = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    };
    isDragging.current = true;
  };

  const handleMouseMove = (e) => {
    if (consoleRef.current) {
      const rect = consoleRef.current.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;

      const dx = e.clientX - centerX;
      const dy = e.clientY - centerY;

      // Вычисление углов наклона
      const maxTilt = 20; // максимальный угол наклона
      const tiltX = Math.min(maxTilt, Math.max(-maxTilt, -(dy / rect.height) * maxTilt));
      const tiltY = Math.min(maxTilt, Math.max(-maxTilt, (dx / rect.width) * maxTilt));

      // Вычисление тени
      const shadowIntensity = 40; // Максимальная интенсивность тени
      const shadowX = -(tiltY / maxTilt) * shadowIntensity; // Инверсия для оси X
      const shadowY = (tiltX / maxTilt) * shadowIntensity; // Инверсия для оси Y

      if (isDragging.current) {
        const newX = e.clientX - offset.current.x;
        const newY = e.clientY - offset.current.y;

        consoleRef.current.style.transform = `translate(${newX}px, ${newY}px)`;
      } else {
        consoleRef.current.style.transform = `${consoleRef.current.style.transform.split('px)')[0]}px) rotateX(${tiltX}deg) rotateY(${tiltY}deg)`;

        consoleRef.current.style.boxShadow = `${shadowX}px ${shadowY}px 15px rgba(0, 0, 0, 0.3)`;
      }
    }
  };

  const getCurrentTime = () => {
    const now = new Date();

    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  };

  const handleMouseUp = () => {
    isDragging.current = false;
  };
  const appendText = (text, options = {}) => {
    const newParagraph = document.createElement('p');
    newParagraph.className = 'console-text';

    try {
      if (typeof text === 'object') {
        text = JSON.stringify(text, null, 2);
      }
    } catch (e) {
      text = '';
    }

    newParagraph.textContent = `${getCurrentTime()}: ${text}`;

    // Применение цвета, если указан
    if (options.color) {
      newParagraph.style.color = options.color;
    }

    consoleContentRef.current.appendChild(newParagraph);
    consoleContentRef.current.scrollTop = consoleContentRef.current.scrollHeight;
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    const prevLog = window.console.log;

    window.console.log = (text, options = {}) => {
      appendText(text, options); // Добавление текста с опциями
      prevLog(text); // Сохранение оригинального поведения
    };

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);

      // Восстановление оригинального console.log
      window.console.log = prevLog;
    };
  }, []);

  return (
    <div className="console" ref={consoleRef}>
      <div
        className="topbar"
        onMouseDown={handleMouseDown}
        style={{
          cursor: 'grab',
          padding: '5px',
          textAlign: 'center',
          transformOrigin: 'center',
          transition: 'transform 0.1s ease-out', // сглаживание движения
        }}
      >
        Drag here
      </div>
      <div className="console-content" ref={consoleContentRef}>
        <p className="console-text">Happy coding! 😊</p>
      </div>
    </div>
  );
}
