import React, { useState } from "react";

export default function ControllBtn({
  innerComponent,
  clickHANDLER,
  descripion,
}) {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className="controll-button"
      onClick={clickHANDLER}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      style={{ position: "relative" }} // Для корректного позиционирования tooltip
    >
      {innerComponent}
      {showTooltip && <div className="tooltip">{descripion}</div>}
    </div>
  );
}
