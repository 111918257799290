import { create } from 'zustand';

const useWalletsStore = create((set, get) => ({
  wallets: [],
  addWallet: (wallet) => {
    const { wallets } = get();

    // if (wallets.includes(wallet) || !wallet || wallets.includes(`0x${wallet}`)) {
    //   return;
    // }
    set({
      wallets: [...wallets, wallet.includes('0x') ? wallet : `0x${wallet}`],
    });
  },
  removeWallet: (wallet) => {
    const { wallets } = get();
    set({ wallets: wallets.filter((item) => item !== wallet) });
  },
}));
export default useWalletsStore;
