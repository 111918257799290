import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import useWalletsStore from '../../store/wallets';
import ControllBtn from '../Buttons/ControllBtn';
import RPCs from '../../vars/rpc';
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default function Wallet({ wallet, index }) {
  const [web3Instances, setWeb3] = useState({});
  const [error, setError] = useState(null);
  const [balances, setBalances] = useState({});
  const { removeWallet } = useWalletsStore();
  const [loading, setLoading] = useState(true);

  const removeWalletHandler = (wallet) => {
    removeWallet(wallet);
  };

  useEffect(() => {
    const web3 = new Web3(RPCs[1].url);
    let instances = {
      ethereum: new Web3(RPCs[1].url),
      polygon: new Web3(RPCs[2].url),
      zora: new Web3(RPCs[3].url),
      base: new Web3(RPCs[0].url),
    };

    setWeb3(instances);
  }, [wallet]);
  const togleLoading = (state = undefined) => {
    if (typeof state == 'boolean') {
      setLoading(state);
      return;
    }
    setLoading(!loading);
  };
  const fetchBalances = async (delay = true) => {
    setError(null);
    if (!web3Instances || !wallet) return;
    try {
      togleLoading(true);

      const walletAddress = getWalletAddress();
      if (delay) await sleep(index * 3000);

      const balancePromises = Object.keys(web3Instances).map(async (key) => {
        try {
          const web3 = web3Instances[key];
          const balance = await web3.eth.getBalance(walletAddress);
          return {
            key,
            balance: Web3.utils.fromWei(balance, 'ether').toString().slice(0, 6),
          };
        } catch (err) {
          console.error(`Error fetching balance from ${key}:`, err.message);
          return { key, balance: 'ERROR' };
        }
      });

      const balancesArray = await Promise.all(balancePromises);
      const balancesObject = balancesArray.reduce((acc, { key, balance }) => {
        acc[key] = balance;
        return acc;
      }, {});

      togleLoading(false);

      setBalances(balancesObject);
    } catch (error) {
      setError(error.message);
    }
  };

  const checkWallet = (wallet) => {
    try {
      const web3 = new Web3();

      const sender = web3.eth.accounts.privateKeyToAddress(wallet);
      return true;
    } catch (error) {
      setError(error.message);
      return false;
    }
  };

  useEffect(() => {
    if (!checkWallet(wallet)) return;
    // fetchBalances();
  }, [web3Instances, wallet]);

  const getWalletAddress = () => {
    if (!checkWallet(wallet)) return null;
    return new Web3(RPCs.ethereum).eth.accounts.privateKeyToAddress(wallet);
  };

  return (
    <div className="wallet">
      {error ? (
        <>
          <div className="error">
            {error}
            <div className="private-key">{wallet}</div>
            <div className="controllers">
              <ControllBtn clickHANDLER={() => fetchBalances(false)} innerComponent="↻" descripion={'Refresh'} />
              <ControllBtn descripion={'Remove wallet'} clickHANDLER={() => removeWalletHandler(wallet)} innerComponent="✕" />
              <ControllBtn descripion={'Copy wallet address'} clickHANDLER={() => navigator.clipboard.writeText(getWalletAddress())} innerComponent="📋" />
              <ControllBtn descripion={'Copy wallet private key'} clickHANDLER={() => navigator.clipboard.writeText(wallet)} innerComponent="🔑" />
            </div>
          </div>
        </>
      ) : (
        <div className="content">
          <div className="address">{getWalletAddress()}</div>
          <div className="balances">
            {Object.values(balances).includes('ERROR') && <div className="error-message">Some nodes returned an error. Please try REFRESH again later.</div>}
            <div style={{ width: '100%', position: 'relative' }}>
              <div
                className="loading"
                style={{
                  opacity: loading ? 1 : 0,
                  zIndex: loading ? 10 : -1,
                }}
              >
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
              <select name="BALANCES">
                <option value="0" className="select">
                  total:{' '}
                  {Object.values(balances)
                    .reduce((acc, balance) => {
                      const numericBalance = parseFloat(balance);
                      if (isNaN(numericBalance)) return acc;
                      return acc + numericBalance;
                    }, 0)
                    .toFixed(6)}
                  {' ETH'}
                </option>
                {Object.keys(balances).map((key) => (
                  <option key={key}>
                    {key}: {balances[key] === 'ERROR' ? 'ERROR' : `${balances[key]} ETH`}
                  </option>
                ))}
              </select>
            </div>
            <span>Wallet key: {index}</span>
          </div>

          <div className="controllers">
            <ControllBtn clickHANDLER={() => fetchBalances(false)} innerComponent="↻" descripion={'Refresh'} />
            <ControllBtn descripion={'Remove wallet'} clickHANDLER={() => removeWalletHandler(wallet)} innerComponent="✕" />
            <ControllBtn descripion={'Copy wallet address'} clickHANDLER={() => navigator.clipboard.writeText(getWalletAddress())} innerComponent="📋" />
            <ControllBtn descripion={'Copy wallet private key'} clickHANDLER={() => navigator.clipboard.writeText(wallet)} innerComponent="🔑" />
          </div>
        </div>
      )}
    </div>
  );
}
