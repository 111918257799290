import React from "react";
import { Link } from "react-router-dom";

export default function BackBtn() {
  return (
    <Link to={"/"}>
      <div className="back-btn">Back ←</div>
    </Link>
  );
}
