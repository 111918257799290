import { useEffect, useRef } from 'react';
import './Header.css';
import RpcManager from '../RpcManager/RpcManager';

export default function Header() {
  const headerRef = useRef(null);

  useEffect(() => {}, []);

  return (
    <div className="header" ref={headerRef}>
      <RpcManager />
    </div>
  );
}
