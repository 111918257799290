import React, { useEffect } from "react";

export default function Table({ children }) {
  const defaultTitle = "Menu";
  const [title, setTitle] = React.useState(defaultTitle);

  return (
    <div className="Table">
      <h1>{defaultTitle}</h1>
      {children}
    </div>
  );
}
