import UniswapV3FactoryABI from './abis/uniswapv3factory.json';
import MulticallABI from './abis/multicall.json';
import Multicall2ABI from './abis/multicall2.json';
import ProxyAdminABI from './abis/proxyadmin.json';
import TickLensABI from './abis/ticklens.json';
import SwapRouterABI from './abis/swaprouter.json';
import NFTDescriptorABI from './abis/nftdescriptor.json';
import NonfungibleTokenPositionDescriptorABI from './abis/nonfungibletokenpositiondescriptor.json';
import TransparentUpgradeableProxyABI from './abis/transparentupgradeableproxy.json';
import NonfungiblePositionManagerABI from './abis/nonfungiblepositionmanager.json';
import V3MigratorABI from './abis/v3migrator.json';
import QuoterV2ABI from './abis/quoterv2.json';
import QuoterABI from './abis/qouter.json';
import SwapRouter02ABI from './abis/SwapRouter02.json';
import Permit2ABI from './abis/permit2.json';
import UniversalRouterABI from './abis/universalrouter.json';
import v3StakerAddressABI from './abis/v3stakeraddress.json';
import Pool from './abis/pool.json';

import WrapedNativeTokenAbi from './abis/wrappednativetoken.json';

const deployments = {
  1: {
    WRAPED_NATIVE_TOKEN: {
      address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      decimals: 18,
      symbol: 'WETH',
      isToken: true,
      isNative: true,
      wrapped: false,
      abi: WrapedNativeTokenAbi,
    },
    UniswapV3Factory: { address: ['0x1F98431c8aD98523631AE4a59f267346ea31F984', '0x0227628f3F023bb0B980b67D528571c95c6DaC1c'], abi: UniswapV3FactoryABI },
    Multicall: { address: ['0x1F98415757620B543A52E61c46B32eB19261F984', ''], abi: MulticallABI },
    Multicall2: { address: ['0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696', '0xD7F33bCdb21b359c8ee6F0251d30E94832baAd07'], abi: Multicall2ABI },
    ProxyAdmin: { address: ['0xB753548F6E010e7e680BA186F9Ca1BdAB2E90cf2', '0x0b343475d44EC2b4b8243EBF81dc888BF0A14b36'], abi: ProxyAdminABI },
    TickLens: { address: ['0xbfd8137f7d1516D3ea5cA83523914859ec47F573', ''], abi: TickLensABI },
    Quoter: { address: ['0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6', ''], abi: QuoterABI },
    SwapRouter: { address: ['0xE592427A0AEce92De3Edee1F18E0157C05861564', ''], abi: SwapRouterABI },
    NFTDescriptor: { address: ['0x42B24A95702b9986e82d421cC3568932790A48Ec', '0x3B5E3c5E595D85fbFBC2a42ECC091e183E76697C'], abi: NFTDescriptorABI },
    NonfungibleTokenPositionDescriptor: {
      address: ['0x91ae842A5Ffd8d12023116943e72A606179294f3', '0x5bE4DAa6982C69aD20A57F1e68cBcA3D37de6207'],
      abi: NonfungibleTokenPositionDescriptorABI,
    },
    TransparentUpgradeableProxy: { address: ['0xEe6A57eC80ea46401049E92587E52f5Ec1c24785', ''], abi: TransparentUpgradeableProxyABI },
    NonfungiblePositionManager: {
      address: ['0xC36442b4a4522E871399CD717aBDD847Ab11FE88', '0x1238536071E1c677A632429e3655c799b22cDA52'],
      abi: NonfungiblePositionManagerABI,
    },
    V3Migrator: { address: ['0xA5644E29708357803b5A882D272c41cC0dF92B34', '0x729004182cF005CEC8Bd85df140094b6aCbe8b15'], abi: V3MigratorABI },
    QuoterV2: { address: ['0x61fFE014bA17989E743c5F6cB21bF9697530B21e', '0xEd1f6473345F45b75F8179591dd5bA1888cf2FB3'], abi: QuoterV2ABI },
    SwapRouter02: { address: ['0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45', '0x3bFA4769FB09eefC5a80d6E87c3B9C650f7Ae48E'], abi: SwapRouter02ABI },
    Permit2: { address: ['0x000000000022D473030F116dDEE9F6B43aC78BA3', '0x000000000022D473030F116dDEE9F6B43aC78BA3'], abi: Permit2ABI },
    UniversalRouter: { address: ['0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD', '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD'], abi: UniversalRouterABI },
    v3StakerAddress: { address: ['0xe34139463bA50bD61336E0c446Bd8C0867c6fE65', ''], abi: v3StakerAddressABI },
  },
  7777777: {
    WRAPED_NATIVE_TOKEN: {
      address: '0x4200000000000000000000000000000000000006',
      decimals: 18,
      symbol: 'WETH',
      isToken: true,
      isNative: true,
      wrapped: false,
      abi: WrapedNativeTokenAbi,
    },
    UniswapV3Factory: { address: ['0x7145F8aeef1f6510E92164038E1B6F8cB2c42Cbb', '0x4324A677D74764f46f33ED447964252441aA8Db6'], abi: UniswapV3FactoryABI },
    Multicall2: { address: ['0xA51c76bEE6746cB487a7e9312E43e2b8f4A37C15', '0xA1E7e3A69671C4494EC59Dbd442de930a93F911A'], abi: MulticallABI },
    ProxyAdmin: { address: ['0xd4109824FC80dD41ca6ee8D304ec74B8bEdEd03b', '0x561896C035abFB3C72f754f10fD35f6c450Ffe16'], abi: ProxyAdminABI },
    TickLens: { address: ['0x209AAda09D74Ad3B8D0E92910Eaf85D2357e3044', '0x23C0F71877a1Fc4e20A78018f9831365c85f3064'], abi: TickLensABI },
    NFTDescriptor: { address: ['0xffF2BffC03474F361B7f92cCfF2fD01CFBBDCdd1', '0xf70C8a20496a5201Fd8D01F627c93aE39cDa1999'], abi: NFTDescriptorABI },
    NonfungibleTokenPositionDescriptor: {
      address: ['0xf15D9e794d39A3b4Ea9EfC2376b2Cd9562996422', '0x5BC936a151Fb4CEBD14467Ca9CBf598b7E645fc0'],
      abi: NonfungibleTokenPositionDescriptorABI,
    },
    TransparentUpgradeableProxy: {
      address: ['0x843b0b03c3B3B0434B9cb00AD9cD1D9218E7741b', '0x68EF3669bEd58213edf9Da598f4E1307680839B2'],
      abi: TransparentUpgradeableProxyABI,
    },
    NonfungiblePositionManager: {
      address: ['0xbC91e8DfA3fF18De43853372A3d7dfe585137D78', '0xB8458EaAe43292e3c1F7994EFd016bd653d23c20'],
      abi: NonfungiblePositionManagerABI,
    },
    V3Migrator: { address: ['0x048352d8dCF13686982C799da63fA6426a9D0b60', '0x65ef259b31bf1d977c37e9434658694267674897'], abi: V3MigratorABI },
    QuoterV2: { address: ['0x11867e1b3348F3ce4FcC170BC5af3d23E07E64Df', '0xC195976fEF0985886E37036E2DF62bF371E12Df0'], abi: QuoterV2ABI },
    SwapRouter02: { address: ['0x7De04c96BE5159c3b5CeffC82aa176dc81281557', '0x6B36d761981d82B1e07cF3c4daF4cB4615c4850a'], abi: SwapRouter02ABI },
    Permit2: { address: ['0x000000000022d473030f116ddee9f6b43ac78ba3', '0x000000000022d473030f116ddee9f6b43ac78ba3'], abi: Permit2ABI },
    UniversalRouter: { address: ['0x2986d9721A49838ab4297b695858aF7F17f38014', ''], abi: UniversalRouterABI },
    v3StakerAddress: { address: ['0x5eF5A6923d2f566F65f363b78EF7A88ab1E4206f', '0x5d298AAf21058d14436DBD36940dcB5542b8aFE8'], abi: v3StakerAddressABI },
  },

  8453: {
    WRAPED_NATIVE_TOKEN: {
      address: '0x4200000000000000000000000000000000000006',
      decimals: 18,
      symbol: 'WETH',
      isToken: true,
      isNative: true,
      wrapped: false,
      abi: WrapedNativeTokenAbi,
    },
    UniswapV3Factory: { address: ['0x33128a8fC17869897dcE68Ed026d694621f6FDfD', '0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24'], abi: UniswapV3FactoryABI },
    Multicall: { address: ['0x091e99cb1C49331a94dD62755D168E941AbD0693', '0xd867e273eAbD6c853fCd0Ca0bFB6a3aE6491d2C1'], abi: MulticallABI },
    ProxyAdmin: { address: ['0x3334d83e224aF5ef9C2E7DDA7c7C98Efd9621fA9', '0xD7303474Baca835743B54D73799688990f24a79D'], abi: ProxyAdminABI },
    TickLens: { address: ['0x0CdeE061c75D43c82520eD998C23ac2991c9ac6d', '0xedf6066a2b290C185783862C7F4776A2C8077AD1'], abi: TickLensABI },
    NFTDescriptor: { address: ['0xF9d1077fd35670d4ACbD27af82652a8d84577d9F', '0x4e0caFF1Df1cCd7CF782FDdeD77f020699B57f1a'], abi: NFTDescriptorABI },
    NonfungibleTokenPositionDescriptor: {
      address: ['0x4f225937EDc33EFD6109c4ceF7b560B2D6401009', '0xd7c6e867591608D32Fe476d0DbDc95d0cf584c8F'],
      abi: NonfungibleTokenPositionDescriptorABI,
    },
    TransparentUpgradeableProxy: {
      address: ['0x4615C383F85D0a2BbED973d83ccecf5CB7121463', '0x1E2A708040Eb6Ed08893E27E35D399e8E8e7857E'],
      abi: TransparentUpgradeableProxyABI,
    },
    NonfungiblePositionManager: {
      address: ['0x03a520b32C04BF3bEEf7BEb72E919cf822Ed34f1', '0x27F971cb582BF9E50F397e4d29a5C7A34f11faA2'],
      abi: NonfungiblePositionManagerABI,
    },
    V3Migrator: { address: ['0x23cF10b1ee3AdfCA73B0eF17C07F7577e7ACd2d7', '0xCbf8b7f80800bd4888Fbc7bf1713B80FE4E23E10'], abi: V3MigratorABI },
    QuoterV2: { address: ['0x3d4e44Eb1374240CE5F1B871ab261CD16335B76a', '0xC5290058841028F1614F3A6F0F5816cAd0df5E27'], abi: QuoterV2ABI },
    SwapRouter02: { address: ['0x2626664c2603336E57B271c5C0b26F421741e481', '0x94cC0AaC535CCDB3C01d6787D6413C739ae12bc4'], abi: SwapRouter02ABI },
    Permit2: { address: ['0x000000000022D473030F116dDEE9F6B43aC78BA3', '0x000000000022D473030F116dDEE9F6B43aC78BA3'], abi: Permit2ABI },
    UniversalRouter: { address: ['0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD', '0x050E797f3625EC8785265e1d9BDd4799b97528A1'], abi: UniversalRouterABI },
    v3StakerAddress: { address: ['0x42bE4D6527829FeFA1493e1fb9F3676d2425C3C1', ''], abi: v3StakerAddressABI },
  },
  137: {
    WRAPED_NATIVE_TOKEN: {
      address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
      decimals: 18,
      symbol: 'WMATIC',
      isToken: true,
      isNative: true,
      wrapped: false,
      abi: WrapedNativeTokenAbi,
    },
    UniswapV3Factory: { address: ['0x1F98431c8aD98523631AE4a59f267346ea31F984', '0x1F98431c8aD98523631AE4a59f267346ea31F984'], abi: UniswapV3FactoryABI },
    Multicall: { address: ['0x1F98415757620B543A52E61c46B32eB19261F984', '0x1F98415757620B543A52E61c46B32eB19261F984'], abi: MulticallABI },
    ProxyAdmin: { address: ['0xB753548F6E010e7e680BA186F9Ca1BdAB2E90cf2', '0xB753548F6E010e7e680BA186F9Ca1BdAB2E90cf2'], abi: ProxyAdminABI },
    TickLens: { address: ['0xbfd8137f7d1516D3ea5cA83523914859ec47F573', '0xbfd8137f7d1516D3ea5cA83523914859ec47F573'], abi: TickLensABI },
    Quoter: { address: ['0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6', '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6'], abi: QuoterABI },
    SwapRouter: { address: ['0xE592427A0AEce92De3Edee1F18E0157C05861564', '0xE592427A0AEce92De3Edee1F18E0157C05861564'], abi: SwapRouterABI },
    NFTDescriptor: { address: ['0x42B24A95702b9986e82d421cC3568932790A48Ec', '0x42B24A95702b9986e82d421cC3568932790A48Ec'], abi: NFTDescriptorABI },
    NonfungibleTokenPositionDescriptor: {
      address: ['0x91ae842A5Ffd8d12023116943e72A606179294f3', '0x91ae842A5Ffd8d12023116943e72A606179294f3'],
      abi: NonfungibleTokenPositionDescriptorABI,
    },
    TransparentUpgradeableProxy: {
      address: ['0xEe6A57eC80ea46401049E92587E52f5Ec1c24785', '0xEe6A57eC80ea46401049E92587E52f5Ec1c24785'],
      abi: TransparentUpgradeableProxyABI,
    },
    NonfungiblePositionManager: {
      address: ['0xC36442b4a4522E871399CD717aBDD847Ab11FE88', '0xC36442b4a4522E871399CD717aBDD847Ab11FE88'],
      abi: NonfungiblePositionManagerABI,
    },
    V3Migrator: { address: ['0xA5644E29708357803b5A882D272c41cC0dF92B34', '0xA5644E29708357803b5A882D272c41cC0dF92B34'], abi: V3MigratorABI },
    QuoterV2: { address: ['0x61fFE014bA17989E743c5F6cB21bF9697530B21e', '0x61fFE014bA17989E743c5F6cB21bF9697530B21e'], abi: QuoterV2ABI },
    SwapRouter02: { address: ['0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45', '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45'], abi: SwapRouter02ABI },
    Permit2: { address: ['0x000000000022D473030F116dDEE9F6B43aC78BA3', '0x000000000022D473030F116dDEE9F6B43aC78BA3'], abi: Permit2ABI },
    UniversalRouter: { address: ['0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD', '0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD'], abi: UniversalRouterABI },
    v3StakerAddress: { address: ['0xe34139463bA50bD61336E0c446Bd8C0867c6fE65', '0xe34139463bA50bD61336E0c446Bd8C0867c6fE65'], abi: v3StakerAddressABI },
  },
};
const ABI = {
  UniswapV3FactoryABI,
  Pool,
  MulticallABI,
  Multicall2ABI,
  ProxyAdminABI,
  TickLensABI,
  QuoterABI,
  SwapRouterABI,
  NFTDescriptorABI,
  NonfungibleTokenPositionDescriptorABI,
  TransparentUpgradeableProxyABI,
  NonfungiblePositionManagerABI,
  V3MigratorABI,
  QuoterV2ABI,
  SwapRouter02ABI,
  Permit2ABI,
  UniversalRouterABI,
  v3StakerAddressABI,
  WrapedNativeTokenAbi,
};
export { deployments, ABI };
