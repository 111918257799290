import React, { useRef } from 'react';
import Wallet from './Wallet';
import useWalletsStore from '../../store/wallets';

export default function List() {
  const { wallets, addWallet, removeWallet } = useWalletsStore();
  const listRef = useRef(null);

  const handleDragEnter = (event) => {
    listRef.current.style.backgroundColor = '#00c8ff30';
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const lines = e.target.result.split('\n');

        lines.forEach((line) => {
          addWallet(line.trim().replace('\r', ''));
        });
        listRef.current.style.backgroundColor = 'transparent'; // Сбрасываем цвет фона здесь
      };
      reader.readAsText(file);
    } else {
      listRef.current.style.backgroundColor = 'transparent'; // Сбрасываем цвет фона здесь
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragLeave = (event) => {
    listRef.current.style.backgroundColor = 'transparent';
    event.preventDefault();
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      className="drop-zone"
      ref={listRef}
      style={{
        margin: '0 5px',
        border: wallets.length === 0 ? '1px dashed #00c8ff' : 'none',
      }}
    >
      {wallets.length > 0 ? (
        wallets.map((privateKey, index) => <Wallet key={index} index={index} wallet={privateKey} />)
      ) : (
        <h3>Drag&Drop your wallets here</h3>
      )}
    </div>
  );
}
