import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { div } from 'three/webgpu';

export default function RpcCell({ URL, blockchainName, selected, onClick }) {
  const [rpc, setRpc] = useState(URL);
  const [chain, setChain] = useState(blockchainName);
  const [health, setHealth] = useState(false);
  const [ping, setPing] = useState(0);

  async function fetchBlock() {
    try {
      const time = Date.now();
      const payload = {
        jsonrpc: '2.0',
        method: 'eth_blockNumber',
        params: [],
        id: time,
      };
      const response = await fetch(rpc, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status}`);
      }

      const data = await response.json();
      const timeDiff = Date.now() - time;
      setPing(timeDiff);
      setHealth(true);
    } catch (error) {
      setHealth(false);
      setPing(999);
    }
  }

  useEffect(() => {
    fetchBlock();
    const interval = setInterval(() => {
      fetchBlock();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={classNames('wrapper', { selected: selected })} onClick={onClick}>
      <div className={classNames('rpc-cell', { healthy: health })}>
        <div className="side front">
          <span>{chain}</span>
        </div>
        <div className="side right">
          <span>{ping}</span>
        </div>
        <div className="side top">
          <span>{health ? '✅' : '❌'}</span>
        </div>
      </div>
    </div>
  );
}
