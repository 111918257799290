import { create } from 'zustand';
import rpc from '../vars/rpc';

const useRpcStore = create((set, get) => ({
  rpc: [...rpc],
  selected: rpc[0],
  getSelected: () => {
    return get().selected;
  },
  setSelected: (url) => {
    const { rpc } = get();

    set({ selected: rpc.find((item) => item.url === url) });
  },
  addRpc: (newRpc) => {
    const { rpc } = get();
    set({
      rpc: [...rpc, newRpc],
    });
  },
  removeRpc: (newRpc) => {
    const { rpc } = get();
    set({ rpc: rpc.filter((item) => item !== newRpc) });
  },
}));
export default useRpcStore;
